/**
 * Get's language code used by i18n catalogs
 * 
 * @param {string} lang 
 * @returns 
 */
export const getLanguageCode = (lang = 'sv', supportedLanguages = ['sv', 'en', 'no', 'fi']) => {
  return lang.toLocaleLowerCase() === 'se' || !supportedLanguages.includes(lang) ? 'sv' : lang;
}

export const getCountryCode = (language) => {
  if(language.toLowerCase() === 'sv-se' || language.toLowerCase() === 'sv') {
    return 'se';
  } else if(language.toLowerCase() === 'en-gb' || language.toLowerCase() === 'en') {
    return 'en';
  } else if(language.toLowerCase() === 'fi-fi' || language.toLowerCase() === 'fi') {
    return 'fi';
  } else if(language.toLowerCase() === 'nb-no' || language.toLowerCase() === 'no') {
    return 'no';
  }

  return 'se';
};